<template>
   <div class="max-width">
      <div class="categories">
         <div class="category-list" ref="categoryList">
            <List />
            <!--<HeaderList /> -->
            <!-- <transition-group name="fade-list" v-if="!isHiddenMode">
               <AdviceCategory
                  v-for="category in categories"
                  :key="category._id"
                  :item="category"
                  @editItemsUpdate="addEditItem"
                  :isEditModeEnable="editMode"
                  :isSubCategory="false"
                  :advicesFountBySerachLength="isSearch ? getCategoryAdvicesLength(category.code) : 0"
               />
            </transition-group> -->
            <!-- <transition-group name="fade-list" v-else-if="isHiddenMode">
               <AdviceCategory
                  v-for="category in hiddenCategories"
                  :key="category._id"
                  :item="category"
                  @editItemsUpdate="addEditItem"
                  :isEditModeEnable="editMode"
                  :isSubCategory="false"
                  :isHidden="true"
               />
            </transition-group> -->
            <!--             <transition name="fade-list" v-else>
               <div>Loading</div>
            </transition> -->
         </div>
      </div>
      <!-- <div v-if="editMode" ref="editBtns" class="edit"> -->
      <!-- <transition name="fade">
            <AdviceCategoriesListEditBtns
               :items="categories"
               :hiddenItems="hiddenCategories"
               @hiddenMode="changeHiddenMode"
               :hiddenMode="isHiddenMode"
               @clearListOfEditItems="listOfEditItems = []"
            />
         </transition> -->
      <!-- </div> -->
      <Modal v-model="isOpenModalAdvice" :modalContentClasses="['max-width-content']">
         <AddTipModal v-if="showAddTipModal" @onCancel="isOpenModalAdvice = false" />
      </Modal>
      <!-- <AddTipModal /> -->
      <SWSnackBar ref="snackbar" />
      <button ref="clickImitate" class="clickImitate" @click="imitateClick"></button>
   </div>
</template>

<script>
// import AdviceCategory from '../../components/AdviceCategoriesComponents/AdviceCategory.vue';
// import AdviceCategoriesListEditBtns from '../../components/AdviceCategoriesComponents/AdviceCategoriesListEditBtns.vue';
// import mutations from '../../store/mutation-types';
import Modal from '../../components/UI/Modal.vue';
import actions from '../../store/action-types';
import AddTipModal from './AddTipModal.vue';
//import HeaderList from './HeaderList.vue';
import { SWSnackBar } from 'sw-ui-kit';
//import mutations from '../../store/mutation-types'; !crutch for set the habits category as selected. Use if needed
// export const FORM_SAVE_TO_LIST = 'saveList';
import List from '../../components/List/List.vue';
//import log from '../../logger/logger';

export default {
   provide() {
      return {
         openAdviceModal: this.openAdviceModal,
         snackBar: this.showMessage,
      };
   },
   components: {
      // AdviceCategory,
      // AdviceCategoriesListEditBtns,
      Modal,
      AddTipModal,
      // HeaderList,
      SWSnackBar,
      List,
   },

   data() {
      return {
         listOfEditItems: [],
         isOpenModalAdvice: false,
         showAddTipModal: false,
         resolve: null,
         category: {},
      };
   },
   async created() {
      const categories = await this.$http.get('/resources/categories');
      this.categories = categories.data;
      this.$store.commit(`app/UPDATE_CURRENT_CATEGORY`, { id: this.categories[0]._id, name: this.categories[0].name });
   },
   mounted() {
      this.$nextTick(() => {
         this.$refs.clickImitate.click();
      });

/*       history.pushState(null, null, location.href);
      window.onpopstate = function () {
         history.go(1);
      }; */
     // log(this.$http, { message: 'visited page /', level: 'info' });
   },
   methods: {
      imitateClick() {
         console.log('CLICKED');
      },
      showMessage(message) {
         this.$refs.snackbar.showSnackbar(message, 5000);
      },
      openAdviceModal() {
         this.isOpenModalAdvice = true;
         this.showAddTipModal = true;
         return new Promise(resolve => {
            this.resolve = resolve;
         });
      },
   },
   beforeMount() {
      this.$store.dispatch(`advice/${actions.FETCH}`);
      //! crutch for set the habits category as selected. Use if needed
      /*      console.log("router", this.$route);
      if(this.$route.name === 'habits'){
         this.$store.commit(`advice/${mutations.UPDATE_SELECTED_CATEGORY}`, 'Habits'); //! THE MOST DISGUSTING, CRUEL CRUTCH
      } */
      //!end crutch
   },

   watch: {
      isOpenModalAdvice() {
         if (!this.isOpenModalAdvice) {
            this.resolve();
         }
      },
   },

   computed: {
      // formSaveToList() {
      //    return FORM_SAVE_TO_LIST;
      // },
      // isHiddenMode() {
      //    return this.$store.getters['advice/getHiddenMode'];
      // },
      //    editMode() {
      //       return this.$store.getters['advice/getEditMode'];
      //    },
      //    categories() {
      //       return this.$store.getters['advice/getCategories'];
      //    },
      //    hiddenCategories() {
      //       return this.$store.getters['advice/getHiddenCategories'];
      //    },
      //    isSubcategoty() {
      //       return this.$route.name === 'app-main-subcategory';
      //    },
      //    isCategoriesLoaded() {
      //       return this.$store.getters['advice/getIsCategoriesLoaded'];
      //    },
      //    isSearch() {
      //       return this.$store.getters['advice/getIsSearch'];
      //    },
      //    categoriesBySearch() {
      //       return this.$store.getters['advice/getCategoriesFilterSearch'];
      //    },
      // },
      // watch: {
      //    editMode() {
      //       const heightOfEditBtns = 56;
      //       this.editMode
      //          ? (this.$refs.categoryList.style.paddingBottom = heightOfEditBtns + 'px')
      //          : (this.$refs.categoryList.style.paddingBottom = 0);
      //    },
      //    listOfEditItems() {
      //       this.$store.commit(`advice/${mutations.UPDATE_EDIT_ITEMS}`, this.listOfEditItems);
      //    },
      //    isCategoriesLoaded: {
      //       handler() {
      //          if (this.isCategoriesLoaded) this.$store.dispatch('advice/updateData');
      //       },
      //       immediate: true,
      //    },
      // },
      // mounted() {
      //    this.$nextTick(() => {
      //       document.querySelector('.scroll').scrollTo(0, 0);
      //    });
      // },
      // methods: {
      //    addEditItem(id) {
      //       this.listOfEditItems.includes(id)
      //          ? this.listOfEditItems.splice(this.listOfEditItems.indexOf(id), 1)
      //          : this.listOfEditItems.push(id);
      //    },
      //    changeHiddenMode(data) {
      //       this.isHiddenMode = data;
      //    },
      //    getCategoryAdvicesLength(code) {
      //       return this.categoriesBySearch.filter(advice => advice.parent === code).length;
      //    },
      // },
   },
};
</script>

<style lang="sass" scoped>
.clickImitate
	visibility: hidden
// @import '../../sass/_variables'

// .fade-list-enter-active, .fade-list-leave-active
//    transition: opacity .5s

// .fade-list-enter, .fade-list-leave-to
//    opacity: 0

// .categories
//    position: relative
//    width: 100%
//    padding: 0 10px
//    max-width: 1500px
//    margin: 0 auto

//    &__buy-btn
//       text-align: center

// .category-list
//    padding-bottom: 20px

// .edit
//    display: flex
//    justify-content: space-between
//    flex-basis: 33.3333%
//    width: 100%
//    position: fixed
//    bottom: 0
//    left: 0
//    background-color: #fff
//    height: 54px

// #dark .edit
//    background-color: $app-dark-background

// #sepia .edit
//    background-color: $app-sepia-background

// .max-width
//    margin: 0 auto
//    max-width: 1330px
//    width: 100%
</style>
<style>
.categories {
   max-width: 876px;
   width: 100%;
   margin: 0 auto;
}
.max-width-content {
   max-width: 580px !important;
}
</style>
